import React from 'react'

import { Link } from 'gatsby'

import { routes } from '../../data'

export default function Socials(props) {
  const { showEmail } = props
  const {
    social: { linkedIn, gitHub, codePen, facebook, instagram },
  } = routes

  return (
    <div className="socials">
      {showEmail && (
        <Link to="/contact">
          <i className="far fa-envelope"></i>
        </Link>
      )}
      <a href={linkedIn} target="_blank" rel="noopener noreferrer">
        <i className="fab fa-linkedin"></i>
      </a>
      <a href={gitHub} target="_blank" rel="noopener noreferrer">
        <i className="fab fa-github"></i>
      </a>
    </div>
  )
}
