import React from 'react'

import { Link } from 'gatsby'

export default function TitleWithCTA(props) {
  const { title, subtitle, link, linkExternal, linkText } = props

  return (
    <div className="title-cta">
      {title && <h2 className="title-cta__heading">{title}</h2>}
      {subtitle && <h4 className="title-cta__subtitle">{subtitle}</h4>}
    </div>
  )
}
